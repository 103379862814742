import { Link, useNavigate } from 'react-router-dom';

import {
  Balance,
  BurgerIcon,
  Logo,
  LogoutIcon,
  ThemeSwitch,
  UserCircleIcon,
  CuratorInfo,
  TelegramIcon,
  // LegalInformationStatusBadge,
} from '@/components';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { logout, webmaster } from '@/redux/features/webmasterSlice';
import { routing, tokenLSKey } from '@/constants';
import { toggleMainDrawer } from '@/redux/features/appSlice';

import './TopNavBar.css';
// import { LegalInformationStatus } from '@/types';

export const TopNavBar = () => {
  const {
    login,
    balance,
    //  legalInformation
  } = useAppSelector(webmaster);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logoutHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    localStorage.removeItem(tokenLSKey);
    navigate(`/${routing.LOGIN}`);
    dispatch(logout());
  };

  // let status = legalInformation?.status;

  // const statusTooltipText =
  //   status === undefined
  //     ? 'Укажите данные на странице профиля'
  //     : status === LegalInformationStatus.MODERATION
  //     ? 'Данные на модерации'
  //     : status === LegalInformationStatus.REJECTED
  //     ? 'Данные были отклонены'
  //     : status === LegalInformationStatus.CONFIRMED
  //     ? ''
  //     : '';

  return (
    <div className="top-navbar flex justify-between items-center px-3 md:px-10">
      <div className=" gap-5 items-center hidden lg:flex">
        <div
          className="cursor-pointer no-select hidden lg:block"
          onClick={() => dispatch(toggleMainDrawer())}
        >
          <BurgerIcon />
        </div>

        {/* <div className="hidden lg:block">
          <div className="tooltip tooltip-bottom" data-tip={statusTooltipText}>
            <LegalInformationStatusBadge status={status} />
          </div>
        </div> */}
      </div>

      <div className="logo-wrapper lg:hidden cursor-pointer">
        <div
          className="flex justify-center h-full items-center gap-3"
          onClick={() =>
            (window.location.href = `/${routing.CABINET}/${routing.LEADS}`)
          }
        >
          <div className="logo  hidden sm:block">
            <Logo />
          </div>
          <h1 className="font-bold  text-lg brand-color brand-name">
            LEY-LID.RU
          </h1>
        </div>
      </div>

      <div className="profile-box flex items-center gap-2">
        <div className="social theme-border hidden lg:block">
          <a
            className="flex gap-1 font-medium link"
            href={process.env.REACT_APP_TG_CHANNEL}
            rel="noreferrer"
            target="_blank"
          >
            <TelegramIcon />
            <span>Телеграм канал основателя</span>
          </a>
        </div>
        <CuratorInfo
          curator={{
            name: 'Владислав',
            login: 'Vladislav',
            workTelegram: 'BessonoVlad',
            workPhone: '',
          }}
        />
        <div className="hidden lg:block">
          <Balance balance={balance?.balance} hold={balance?.hold} />
        </div>
        <div className="hidden lg:block"></div>
        <div className="profile mr-2 flex items-center gap-3 text-lg">
          <div className="dropdown  dropdown-hover dropdown-end ">
            <label
              className="bg-primary h-11 w-11 cursor-pointer flex items-center justify-center text-white rounded-full "
              style={{ padding: '0.55rem', scale: '.8' }}
            >
              {login && login[0].toUpperCase()}
            </label>

            <div className="dropdown-content shadow-md menu p-2 dropdown-profile bg-base-100 rounded-md w-52">
              <div>
                <div className="flex gap-2 items-center">
                  <div
                    className="bg-primary h-11 w-11 cursor-pointer flex items-center justify-center text-white rounded-full "
                    style={{ padding: '0.55rem', scale: '.8' }}
                  >
                    {login && login[0].toUpperCase()}
                  </div>
                  <div>
                    <p>{login}</p>
                    <p className="text-sm stat-title">Вебмастер</p>
                  </div>
                </div>
                <ul className="mt-2 pt-2 border-t ">
                  <li className="">
                    <Link to={routing.PROFILE} className="pl-2 py-2 text-base ">
                      <UserCircleIcon />
                      <span>Профиль</span>
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(e) => logoutHandler(e)}
                      className="pl-2 py-2 text-base"
                    >
                      <LogoutIcon /> Выйти
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="icons-menu   gap-3 items-center">
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
};
