export const routing = {
  MAIN: '/',
  LOGIN: 'login',
  REGISTRATION: 'registration',
  BANNED: 'banned',
  CABINET: 'cab',
  LEADS: 'leads',
  ADD_LEAD: 'add-lead',
  NEWS: 'news',
  OFFERS: 'offers',
  PROFILE: 'profile',
  FINANCES: 'finances',
  REFERRALS: 'referrals',
  INTEGRATION: 'integration',
  PASSWORD_RECOVERY: 'password-recovery',
  TERMS_OF_SERVICES: 'terms-of-services',
};

export const YM_COUNTER = 92459737;

export const dateConfig: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const tokenLSKey = 'w-ll-token';
export const refDomain = 'ley-lid.ro';

export const bankNames = [
  'Сбер',
  'Тинькофф',
  'Альфа-Банк',
  'ВТБ',
  'Точка',
  'Открытие',
  'Райффайзен',
  'Почта Банк',
  'Промсвязьбанк',
  'Газпромбанк',
  'МТС Банк',
  'Россельхозбанк',
  'Совкомбанк',
  'Русский Стандарт',
  'Росбанк',
];
