import { IMoneyMovementRecord, MoneyMovementType } from '@/types';
import { MoneyMovementStatusBadge } from '../MoneyMovementStatusBadge/MoneyMovementStatusBadge';

type Props = {
  records: IMoneyMovementRecord[];
};

export const MovementTable: React.FC<Props> = ({ records }) => {
  return (
    <div className='overflow-x-auto '>
      <table className='table table-compact w-full'>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Описание</th>
            <th>Сумма</th>
            <th>Тип</th>
            <th>Cтатус</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record) => {
            return (
              <tr className='hover' key={record.id}>
                <td>
                  {new Date(record.createdAt)
                    .toLocaleDateString(navigator.language, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(',', '')}
                </td>
                <td>{record.description}</td>
                <td>
                  {record.type === MoneyMovementType.PAYOUT_PER_LEAD ||
                  record.type === MoneyMovementType.REFERRAL_INCOME
                    ? `${record.sum} К`
                    : `-${Math.abs(record.sum)} К`}
                </td>

                <td>
                  {record.type === MoneyMovementType.PAYOUT_PER_LEAD
                    ? 'Выплата за лид'
                    : record.type === MoneyMovementType.PAYMENT
                    ? 'Вывод средств'
                    : record.type === MoneyMovementType.REFERRAL_INCOME &&
                      'Выплата за лид реферала'}
                </td>
                <td>
                  <MoneyMovementStatusBadge status={record.status} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
