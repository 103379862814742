import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { webmasterAPI } from './api/webmasterAPI';
import { newsAPI } from './api/newsAPI';
import { offersAPI } from './api/offersAPI';

import appReducer from './features/appSlice';
import leadsReducer from './features/leadsSlice';
import financesReducer from './features/financesSlice';
import webmasterReducer from './features/webmasterSlice';
import referralsReducer from './features/referralsSlice';
import passwordRecoveryReducer from './features/passwordRecoverySlice';
import searchesReducer from './features/searchesSlice';

import { financesAPI } from './api/financesAPI';
import { citiesAPI } from './api/citiesAPI';
import { offerCategoriesAPI } from './api/offerCategoriesAPI';
import { leadsAPI } from './api/leadsAPI';
import { statsAPI } from './api/statsAPI';
import { visitsAPI } from './api/visitsAPI';
import { referralsAPI } from './api/referralsAPI';
import { apiTokensAPI } from './api/apiTokensAPI';
import { legalInformationAPI } from './api/legalInformationAPI';

export const rootReducer = combineReducers({
  app: appReducer,
  webmaster: webmasterReducer,
  leads: leadsReducer,
  finances: financesReducer,
  referrals: referralsReducer,
  passwordRecovery: passwordRecoveryReducer,
  searches: searchesReducer,
  [webmasterAPI.reducerPath]: webmasterAPI.reducer,
  [financesAPI.reducerPath]: financesAPI.reducer,
  [newsAPI.reducerPath]: newsAPI.reducer,
  [offersAPI.reducerPath]: offersAPI.reducer,
  [citiesAPI.reducerPath]: citiesAPI.reducer,
  [leadsAPI.reducerPath]: leadsAPI.reducer,
  [offerCategoriesAPI.reducerPath]: offerCategoriesAPI.reducer,
  [statsAPI.reducerPath]: statsAPI.reducer,
  [visitsAPI.reducerPath]: visitsAPI.reducer,
  [referralsAPI.reducerPath]: referralsAPI.reducer,
  [apiTokensAPI.reducerPath]: apiTokensAPI.reducer,
  [legalInformationAPI.reducerPath]: legalInformationAPI.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        webmasterAPI.middleware,
        newsAPI.middleware,
        offersAPI.middleware,
        citiesAPI.middleware,
        offerCategoriesAPI.middleware,
        leadsAPI.middleware,
        financesAPI.middleware,
        statsAPI.middleware,
        visitsAPI.middleware,
        referralsAPI.middleware,
        apiTokensAPI.middleware,
        legalInformationAPI.middleware,
      ]),
  });
};
