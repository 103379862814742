import { tokenLSKey } from "@/constants";
import { IStatsResponse, IWebmasterStatsFromPeriods } from "@/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const statsAPI = createApi({
  reducerPath: "statsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/stats/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Stats"],
  endpoints: (builder) => ({
    getStatsFromPeriods: builder.query<IWebmasterStatsFromPeriods, null>({
      query: () => ({
        url: "webmaster/periods",
        method: "GET",
      }),
      providesTags: (result) => ["Stats"],
    }),
    getStats: builder.query<IStatsResponse, null>({
      query: () => ({
        url: "webmaster",
        method: "GET",
      }),
      providesTags: (result) => ["Stats"],
    }),
  }),
});

export const { useGetStatsFromPeriodsQuery, useGetStatsQuery } = statsAPI;
