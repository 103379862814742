import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { webmaster } from '@/redux/features/webmasterSlice';
import { togglePaymentModal } from '@/redux/features/financesSlice';
import { QuestionIcon } from '@/components/Icons/QuestionIcon';

export const PaymentBlock = () => {
  const dispatch = useAppDispatch();
  const { balance, bankCards } = useAppSelector(webmaster);
  const [toolTipText, setToolTipText] = useState<string | null>(null);

  const openModalHandler = () => dispatch(togglePaymentModal());

  useEffect(() => {
    if (balance === null) return;

    if (!bankCards.length) {
      return setToolTipText('Добавьте карту на странице профиля');
    }
    if (!balance.balance) return setToolTipText('Пока что Ваш баланс пуст');
    setToolTipText('');
  }, [balance, bankCards]);

  return (
    <div className="bg-base-100 theme-border rounded-md shadow px-6 py-4">
      <label className="label">
        <span className="label-text flex items-center gap-2">
          <span>Вывод средств</span>
          <span
            className="tooltip lg:tooltip-right tooltip-bottom"
            data-tip={'Вывод средств до 7 рабочих дней. 1К равен 1 рублю'}
          >
            <span className="question-icon">
              <QuestionIcon />
            </span>
          </span>
        </span>
      </label>

      {toolTipText === null ? (
        <button className="btn loading btn-sm btn-primary w-full md:w-32"></button>
      ) : toolTipText ? (
        <div
          className="tooltip tooltip-bottom md:tooltip-right w-full md:w-32"
          data-tip={toolTipText}
        >
          <button disabled className="btn btn-primary btn-sm w-full md:w-32">
            Вывести
          </button>
        </div>
      ) : (
        <button
          onClick={openModalHandler}
          className="btn btn-primary btn-sm  w-full md:w-32"
        >
          Вывести
        </button>
      )}
    </div>
  );
};
