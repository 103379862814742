import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IOfferCategory } from "@/types";

export const offerCategoriesAPI = createApi({
  reducerPath: "offerCategoriesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/offer-categories/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getAllCategories: builder.query<IOfferCategory[], null>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: (result) => ["Categories"],
    }),
  }),
});

export const { useGetAllCategoriesQuery } = offerCategoriesAPI;
