import { routing } from '@/constants';
import React from 'react';

type Props = {
  publicOfferState: boolean;
  changePublicOfferState: (state: boolean) => void;
};

export const RulesAndConvention: React.FC<Props> = ({
  publicOfferState,
  changePublicOfferState,
}) => {
  return (
    <div className="noselect">
      <label className="mt-4 text-sm flex cursor-pointer">
        <input
          type="checkbox"
          checked={publicOfferState}
          onChange={() => changePublicOfferState(!publicOfferState)}
          className="checkbox checkbox-primary checkbox-sm"
        />

        <span className="ml-2">
          Принимаю условия{' '}
          <a
            className="link link-primary"
            href={`/${routing.TERMS_OF_SERVICES}`}
            target="_blank"
            rel="noreferrer"
          >
            Пользовательского соглашения
          </a>
        </span>
      </label>
    </div>
  );
};
