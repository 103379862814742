import { IReferralsState, RootState } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IReferralsState = {
  startDate: null,
  endDate: null,
  progressModalIsOpen: false,
  incomesTotal: null,
  refsTotal: null,
  refsEarnedTotal: null,
  progressMax: 1500000,
};

export const referralsSlice = createSlice({
  initialState,
  name: 'referrals',
  reducers: {
    setReferralsStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.startDate = action.payload;
      } else {
        state.startDate = null;
      }
    },
    setReferralsEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.endDate = action.payload;
      } else {
        state.endDate = null;
      }
    },
    toggleProgressModal: (state) => {
      state.progressModalIsOpen = !state.progressModalIsOpen;
    },
    setStatsInfo: (
      state,
      action: PayloadAction<
        Pick<IReferralsState, 'incomesTotal' | 'refsTotal' | 'refsEarnedTotal'>
      >
    ) => {
      state.incomesTotal = action.payload.incomesTotal;
      state.refsTotal = action.payload.refsTotal;
      state.refsEarnedTotal = action.payload.refsEarnedTotal;
    },
  },
});

export default referralsSlice.reducer;

export const {
  setReferralsStartDate,
  setReferralsEndDate,
  toggleProgressModal,
  setStatsInfo,
} = referralsSlice.actions;
export const referrals = (state: RootState) => state.referrals;
