import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IRefVisit } from "@/types";

export const visitsAPI = createApi({
  reducerPath: "visitsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/visits",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getRefVisits: builder.query<
      IRefVisit[],
      { start: string | undefined; end: string | undefined }
    >({
      query: (params) => ({
        url: "periods",
        method: "GET",
        params: {
          start: params?.start,
          end: params?.end,
        },
      }),
    }),
  }),
});

export const { useGetRefVisitsQuery } = visitsAPI;
