import { useGetAllСitiesQuery } from "@/redux/api/citiesAPI";
import { useEffect, useState } from "react";

type Props = {
  valueHandler: (cityId: number) => void;
  selectedId?: number;
  noCategory?: boolean;
};

export const SelectCity: React.FC<Props> = ({
  valueHandler,
  selectedId,
  noCategory,
}) => {
  const { data, isFetching, isLoading } = useGetAllСitiesQuery(null);
  const loading = isFetching || isLoading;

  const [selected, setSelected] = useState<number>(0);

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(parseInt(event.target.value));
    valueHandler(parseInt(event.target.value));
  };

  useEffect(() => {
    if (selectedId) setSelected(selectedId);
  }, [selectedId]);

  return (
    <>
      {loading ? (
        ""
      ) : (
        <>
          {data && data.length ? (
            <>
              <label className="label">
                <span className="label-text">Город</span>
              </label>
              <select
                className="select select-sm select-bordered w-full lg:max-w-xs"
                value={selected}
                onChange={changeSelect}
              >
                {noCategory ? (
                  <option value={0}>Все города </option>
                ) : (
                  <option disabled value={0}>
                    Выберите город
                  </option>
                )}

                {data?.map((city) => {
                  return (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            <select className="select select-sm w-full select-bordered max-w-xs ">
              <option disabled selected>
                Нет городов
              </option>
            </select>
          )}
        </>
      )}
    </>
  );
};
