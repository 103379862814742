import { IAppState, IWebmasterStatsFromPeriods, RootState } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const drawerState = localStorage.getItem('drawer');

const initialState: IAppState = {
  theme: 'winter',
  activeStatsTab: 'today',
  mainDrawerIsOpen: drawerState ? Boolean(parseInt(drawerState)) : true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<'winter' | 'halloween'>) => {
      state.theme = action.payload;
    },
    setActiveStatsTab: (
      state,
      action: PayloadAction<keyof IWebmasterStatsFromPeriods>
    ) => {
      state.activeStatsTab = action.payload;
    },
    toggleMainDrawer: (state) => {
      state.mainDrawerIsOpen = !state.mainDrawerIsOpen;
      localStorage.setItem('drawer', state.mainDrawerIsOpen ? '1' : '0');
    },
  },
});

export default appSlice.reducer;
export const { changeTheme, setActiveStatsTab, toggleMainDrawer } =
  appSlice.actions;
export const app = (state: RootState) => state.app;
