import { useGetStatsQuery } from '@/redux/api/statsAPI';
import { LeadStatus } from '@/types';

type Props = {
  status: string | undefined;
  total: number;
  totalWithParams: number | null;
  sum: number | null;
};

export const LeadsStatTable: React.FC<Props> = ({
  status,
  sum,
  total,
  totalWithParams,
}) => {
  const { data: stats } = useGetStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className='flex justify-between pr-2  w-full'>
      {totalWithParams === null ? (
        <div className='flex items-center gap-5 justify-between flex-wrap w-full'>
          <div className='flex gap-5'>
            <div>
              Всего заработано: <b>{stats?.totalEarned} К</b>
            </div>
            <div>
              Всего лидов: <b>{total}</b>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='flex gap-5'>
            {sum && status && (
              <div>
                {status === LeadStatus.APPROVED && (
                  <>
                    Заработано: <b>{sum} К</b>
                  </>
                )}
                {status === LeadStatus.IN_WORK && (
                  <>
                    В работе: <b>{sum} К</b>
                  </>
                )}
              </div>
            )}

            <div>
              Кол-во лидов: <b>{totalWithParams}</b>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
