import { IFinancesState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IFinancesState = {
  startDate: null,
  endDate: null,
  paymentModalIsOpen: false,
};

export const financesSlice = createSlice({
  initialState,
  name: "finances",
  reducers: {
    setFinancesStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.startDate = action.payload;
      } else {
        state.startDate = null;
      }
    },
    setFinancesEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.endDate = action.payload;
      } else {
        state.endDate = null;
      }
    },
    togglePaymentModal: (state) => {
      state.paymentModalIsOpen = !state.paymentModalIsOpen;
    },
  },
});

export default financesSlice.reducer;

export const { setFinancesStartDate, setFinancesEndDate, togglePaymentModal } =
  financesSlice.actions;
export const finances = (state: RootState) => state.finances;
