import { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { app, changeTheme } from '@/redux/features/appSlice';

import { Routing } from './Routing';

import 'react-notifications/lib/notifications.css';
import './App.css';

function App() {
  const { theme } = useAppSelector(app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (!theme) return;

    if (theme === 'winter' || theme === 'halloween') {
      dispatch(changeTheme(theme));
    }
  }, [dispatch]);

  return (
    <div className="app bg-base-200" data-theme={theme}>
      <NotificationContainer />

      <Routing />
    </div>
  );
}

export default App;
