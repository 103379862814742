import { ICurator } from '@/types';
import {
  //  PhoneIcon,
  TelegramIcon,
} from '@/components';
import './CuratorInfo.css';
type Props = {
  curator: ICurator;
};

export const CuratorInfo: React.FC<Props> = ({ curator }) => {
  return (
    <div className="curator theme-border">
      <div className="dropdown dropdown-hover dropdown-end ">
        <div className=" items-center gap-1 hidden lg:flex">
          <span>Поддержка:</span>
          <span className="font-medium capitalize link">{curator.name}</span>
        </div>
        {/* <div className=" items-center gap-1  lg:hidden">
          <span className="font-medium capitalize link">куратор</span>
        </div> */}

        <div className="min-w-[200px] dropdown-content shadow-md menu p-2 dropdown-profile bg-base-100 rounded-md ">
          <div>
            <div className="flex gap-2 items-center">
              <div
                className="bg-accent h-11 w-11 cursor-pointer flex items-center justify-center text-white rounded-full "
                style={{ padding: '0.55rem', scale: '.8' }}
              >
                {curator.login && curator.login[0].toUpperCase()}
              </div>
              <div>
                <p>Владислав</p>
                {/* <p className="text-sm stat-title">Куратор</p> */}
              </div>
            </div>
            <div className="p-2 mt-2 theme-border">
              {curator.workTelegram && (
                <a
                  className="link-primary mt-2 flex gap-2"
                  target="_blank"
                  href={`https://t.me/${curator.workTelegram}`}
                  rel="noreferrer"
                >
                  <TelegramIcon /> @{curator.workTelegram}
                </a>
              )}
              {/* {curator.workPhone && (
                <a
                  className="flex link-primary gap-2 mt-2 "
                  href={`tel:${curator.workPhone}`}
                >
                  <PhoneIcon /> {curator.workPhone}
                </a>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
