import { routing } from '@/constants';
import { Link } from 'react-router-dom';

type Props = {
  hold: number | undefined;
  balance: number | undefined;
};

export const Balance: React.FC<Props> = ({ balance, hold }) => {
  return (
    <div className='balance gap-3 lg:gap-5 mr-5 flex '>
      <Link to={`/${routing.CABINET}/${routing.FINANCES}`}>
        Баланс:{' '}
        <span className='lg:text-lg font-medium'>
          {balance ? balance : 0} К
        </span>
      </Link>
      <div>
        Холд:{' '}
        <span className='lg:text-lg font-medium'>{hold ? hold : 0} К</span>
      </div>
    </div>
  );
};
