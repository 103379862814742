import { ILead, LeadStatus } from '@/types';

import { LeadStatusBadge, TelephonyCompanyCeil } from '@/components';
import { useAppSelector } from '@/hooks/useRedux';
import { webmaster } from '@/redux/features/webmasterSlice';
import './LeadsTable.css';

type Props = {
  leads: ILead[];
};

export const LeadsTable: React.FC<Props> = ({ leads }) => {
  const { telephony } = useAppSelector(webmaster);

  return (
    <div className='overflow-x-auto'>
      <table className='table w-full table-compact'>
        <thead>
          <tr>
            <th>Дата</th>
            <th>ID</th>
            <th>Категория</th>
            <th>Город</th>
            <th className='address'>Адрес</th>
            <th>Тел. клиента</th>
            <th>Рекл. кампания</th>

            <th>Выплата</th>
            <th>Cтатус</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => {
            return (
              <tr className='hover' key={lead.amoId}>
                <td>
                  {new Date(lead.createdAt)
                    .toLocaleDateString(navigator.language, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(',', '')}
                </td>
                <td>{lead.amoId}</td>
                <td>{lead.categoryName ? lead.categoryName : '----'}</td>
                <td>{lead.cityName ? lead.cityName : '----'}</td>
                <td className='address'>
                  {lead.address ? lead.address : '----'}
                </td>
                <td>{lead.hiddenLeadPhone}</td>
                <TelephonyCompanyCeil
                  number={lead.trackingPhone}
                  telephony={telephony}
                />

                <td>
                  {lead.sum
                    ? lead.status === LeadStatus.APPROVED ||
                      lead.status === LeadStatus.IN_WORK
                      ? lead.sum + ' К'
                      : '----'
                    : '----'}
                </td>
                <td>
                  <LeadStatusBadge
                    comment={lead.comment}
                    status={lead.status}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
