import { Link } from "react-router-dom";

import { INewsPrewiew } from "@/types";

import "./NewsItem.css";
import { routing } from "@/constants";

export const NewsItem: React.FC<{
  item: INewsPrewiew;
}> = ({ item }) => {
  const publishDate = new Date(item.createdAt);
  const year = publishDate.getFullYear();
  const month = publishDate.getMonth() + 1;
  const date = publishDate.getDate();

  return (
    <div className="news-item flex flex-col p-5 bg-base-100  rounded-md my-5 shadow">
      <div>
        <Link
          to={`/${routing.CABINET}/${routing.NEWS}/${item.id}`}
          className="title text-2xl font-medium "
        >
          {item.title}
        </Link>
        <div className="stat-title mt-1">
          <div className="hidden sm:block">
            дата публикации: {year}-{month < 10 ? `0${month}` : month}-{date}
          </div>
        </div>
      </div>
      <div className="actions-buttons  mt-5 ">
        <Link
          className="btn btn-primary btn-sm lowercase"
          to={`/${routing.CABINET}/${routing.NEWS}/${item.id}`}
        >
          посмотреть
        </Link>
      </div>
    </div>
  );
};
