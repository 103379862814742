import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { routing } from './constants';
import { AuthLayout, Layout } from './components';

import { Login } from '@/pages/Login/Login';
import { ReferralPage } from '@/pages/ReferralPage/ReferralPage';
import { CuratorPage } from '@/pages/CuratorPage/CuratorPage';

const BannedPage = lazy(() => import('./pages/BannedPage/BannedPage'));
const Offers = lazy(() => import('./pages/Offers/Offers'));
const SingleOffer = lazy(() => import('./pages/SingleOffer/SingleOffer'));
const News = lazy(() => import('./pages/News/News'));
const SingleNews = lazy(() => import('./pages/SingleNews/SingleNews'));
const Finances = lazy(() => import('./pages/Finances/Finances'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Leads = lazy(() => import('./pages/Leads/Leads'));
const AddLead = lazy(() => import('./pages/AddLead/AddLead'));

const Registration = lazy(() => import('./pages/Registration/Registration'));

const Referrals = lazy(() => import('./pages/Referrals/Referrals'));
// const Integration = lazy(() => import('./pages/Integration/Integration'));
const PasswordRecovery = lazy(
  () => import('./pages/PasswordRecovery/PasswordRecovery')
);
const TermsOfServices = lazy(
  () => import('./pages/TermsOfServices/TermsOfServices')
);

export const Routing = () => {
  return (
    <Routes>
      <Route path={routing.LOGIN} element={<Login />} />
      <Route
        path={routing.REGISTRATION}
        element={
          <Suspense>
            <Registration />
          </Suspense>
        }
      />

      <Route
        path={routing.PASSWORD_RECOVERY}
        element={
          <Suspense>
            <PasswordRecovery />
          </Suspense>
        }
      />

      <Route
        path={routing.BANNED}
        element={
          <Suspense>
            <BannedPage />
          </Suspense>
        }
      />

      <Route
        path={routing.TERMS_OF_SERVICES}
        element={
          <Suspense>
            <TermsOfServices />
          </Suspense>
        }
      />

      <Route path={routing.MAIN} element={<Navigate to={routing.CABINET} />} />
      <Route path={`${routing.MAIN}/:id`} element={<ReferralPage />} />
      <Route path={`${routing.MAIN}/c/:id`} element={<CuratorPage />} />

      <Route path={routing.CABINET} element={<AuthLayout />}>
        <Route path="" element={<Layout />}>
          <Route index element={<Navigate to={routing.LEADS} />} />
          <Route
            path={routing.LEADS}
            element={
              <Suspense>
                <Leads />
              </Suspense>
            }
          />
          <Route
            path={routing.ADD_LEAD}
            element={
              <Suspense>
                <AddLead />
              </Suspense>
            }
          />
          <Route
            path={routing.NEWS}
            element={
              <Suspense>
                <News />
              </Suspense>
            }
          />
          <Route
            path={`${routing.NEWS}/:id`}
            element={
              <Suspense>
                <SingleNews />
              </Suspense>
            }
          />
          <Route
            path={routing.OFFERS}
            element={
              <Suspense>
                <Offers />
              </Suspense>
            }
          />
          <Route
            path={`${routing.OFFERS}/:id`}
            element={
              <Suspense>
                <SingleOffer />
              </Suspense>
            }
          />
          <Route
            path={routing.PROFILE}
            element={
              <Suspense>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={routing.FINANCES}
            element={
              <Suspense>
                <Finances />
              </Suspense>
            }
          />
          <Route
            path={routing.REFERRALS}
            element={
              <Suspense>
                <Referrals />
              </Suspense>
            }
          />
          {/* <Route
            path={routing.INTEGRATION}
            element={
              <Suspense>
                <Integration />
              </Suspense>
            }
          /> */}
          <Route path="*" element={<Navigate to="leads" />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={routing.LOGIN} />} />
    </Routes>
  );
};
