import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { PageLoader } from "@/components";
import { routing, tokenLSKey } from "@/constants";

export const CuratorPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem(tokenLSKey)) {
      return navigate(`/${routing.CABINET}/${routing.LEADS}`);
    }
    const reqex = /^\d+$/;
    if (!reqex.test(params.id!)) return navigate(`/${routing.LOGIN}`);

    const cookies = new Cookies();
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("cid", params.id, { path: "/", expires: date });
    cookies.remove("refId");
    window.location.href = `/${routing.REGISTRATION}`;
  }, [params, navigate]);

  return <PageLoader />;
};
