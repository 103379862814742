import { IIncomesByLogin } from '@/types';

type Props = {
  data: IIncomesByLogin[];
};

export const ProfitTableByLogins: React.FC<Props> = ({ data }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='table w-full table-compact'>
        <thead>
          <tr>
            <th>Реферал</th>
            <th>Всего лидов</th>
            <th>Подтвержд. лидов</th>
            <th>Доход</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.login} className='hover'>
              <td>{item.login}</td>
              <td>{item.totalLeads}</td>
              <td>{item.leads}</td>
              <td>{item.incomes} К</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
