import { IOffer } from "@/types";import { OfferItem } from "../OfferItem/OfferItem";

type Props = {
  offers: IOffer[];
};

export const OffersList: React.FC<Props> = ({ offers }) => {
  console.log(offers);
  
  return (
    <>
      {offers.map((offer) => {
        return <OfferItem key={offer.id} offer={offer} />;
      })}
    </>
  );
};
