import { useState } from 'react';
import { TbEye, TbEyeOff } from 'react-icons/tb';

type Props = {
  password: string;
  changeHandler: (value: string) => void;
  placeholder?: string;
  className?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
};

export const PasswordInput: React.FC<Props> = ({
  password,
  changeHandler,
  placeholder,
  className,
  size,
}) => {
  const [isHidden, setHidden] = useState(true);

  const classes = `input input-bordered pr-8 w-full mb-5 ${className || ''} ${
    size ? `input-${size}` : ''
  }`;

  const eyeTopOffset = size === 'sm' ? 'top-[7px]' : 'top-[15px]';

  return (
    <>
      <input
        type={isHidden ? 'password' : 'text'}
        placeholder={placeholder || ''}
        className={classes}
        value={password}
        onChange={(e) => changeHandler(e.target.value)}
      />
      <span
        className={`cursor-pointer eye absolute ${eyeTopOffset} right-2`}
        onClick={() => setHidden(!isHidden)}
      >
        {isHidden ? <TbEye size={20} /> : <TbEyeOff size={20} />}
      </span>
    </>
  );
};
