import { NotificationManager as notify } from "react-notifications";
import { RotateIcon } from "@/components/Icons/RotateIcon";
import { TrashIcon } from "@/components/Icons/TrashIcon";
import { useAppSelector } from "@/hooks/useRedux";
import {
  useCreateTokenMutation,
  useGetTokenQuery,
  useRegenerateTokenMutation,
  useRemoveTokenMutation,
} from "@/redux/api/apiTokensAPI";
import { webmaster } from "@/redux/features/webmasterSlice";
import { useEffect } from "react";

export const ApiKeysBlock = () => {
  useGetTokenQuery();
  const { apiKey } = useAppSelector(webmaster);

  const [createToken, { isSuccess: isSuccessCreate }] =
    useCreateTokenMutation();
  const [removeToken, { isSuccess: isSuccessRemove }] =
    useRemoveTokenMutation();
  const [regenerateToken, { isSuccess: isSuccessRegenerate }] =
    useRegenerateTokenMutation();

  useEffect(() => {
    if (!isSuccessCreate) return;
    notify.success("API ключ создан");
  }, [isSuccessCreate]);

  useEffect(() => {
    if (!isSuccessRemove) return;
    notify.error("API ключ удален");
  }, [isSuccessRemove]);

  useEffect(() => {
    if (!isSuccessRegenerate) return;
    notify.success("API ключ перевыпущен");
  }, [isSuccessRegenerate]);

  return (
    <div>
      <div className="text-xl font-medium my-3">Интеграции</div>

      {apiKey ? (
        <div className="form-control w-full lg:max-w-xl">
          <label className="label mt-3 sm:mt-0">
            <span className="label-text">API ключ</span>
          </label>

          <div className="flex items-center gap-3  mb-3">
            <input
              onChange={() => {}}
              type="text"
              value={apiKey}
              className="input input-bordered input-sm w-full"
            />
            <span className="tooltip tooltip-top" data-tip={"Перевыпустить"}>
              <div className="cursor-pointer" onClick={() => regenerateToken()}>
                <RotateIcon />
              </div>
            </span>
            <div className="cursor-pointer" onClick={() => removeToken()}>
              <TrashIcon />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="text-xl p-3 no-data-text font-thin">
            У Вас нет API ключей
          </div>
          <button
            onClick={() => createToken()}
            className="btn btn-success btn-sm mt-3"
          >
            Получить ключ
          </button>
        </>
      )}
    </div>
  );
};
