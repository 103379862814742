import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IMoneyMovementResponse } from "@/types";
import { removeFromBalance } from "../features/webmasterSlice";

export const financesAPI = createApi({
  reducerPath: "financesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Finances"],
  endpoints: (builder) => ({
    getAllRecords: builder.query<
      IMoneyMovementResponse,
      {
        offset: number;
        limit: number;
        type: string | undefined;
        status: string | undefined;
        start: string | undefined;
        end: string | undefined;
      }
    >({
      query: (params) => ({
        url: "webmaster-money-movement",
        method: "GET",
        params: {
          offset: params?.offset,
          limit: params?.limit,
          status: params?.status,
          type: params?.type,
          start: params?.start,
          end: params?.end,
        },
      }),
      providesTags: (result) => ["Finances"],
    }),

    getPayment: builder.mutation<
      any,
      {
        sum: number;
        bankCard: string;
        bankCardName: string;
        fio: string;
        login: string;
      }
    >({
      query: (body) => ({
        url: "payments/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Finances"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(removeFromBalance(data.sum));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetAllRecordsQuery, useGetPaymentMutation } = financesAPI;
