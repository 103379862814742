import { tokenLSKey } from "@/constants";
import { ICity } from "@/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const citiesAPI = createApi({
  reducerPath: "citiesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/cities/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Cities"],
  endpoints: (builder) => ({
    getAllСities: builder.query<ICity[], null>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: (result) => ["Cities"],
    }),
  }),
});

export const { useGetAllСitiesQuery } = citiesAPI;
