import { LossReasonText } from '@/types';

export const getLossReasonText = (comment: string | null) => {
  if (!comment) return null;

  switch (comment) {
    case LossReasonText.NOT_DIALING:
      return 'Пропала связь';
    case LossReasonText.INSOLVENT_PERSON:
      return 'Неплатёж. лицо';
    default:
      return comment;
  }
};
