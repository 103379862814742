import { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useAppSelector } from '@/hooks/useRedux';
import { useChangeContactsMutation } from '@/redux/api/webmasterAPI';
import { webmaster } from '@/redux/features/webmasterSlice';
import { validateTelegram, validatePhone } from '@/utils';
import { IErrorResponse } from '@/types';

export const ProfileInfo = () => {
  const profile = useAppSelector(webmaster);

  const [changeContacts, { error, isSuccess }] = useChangeContactsMutation();

  const [workTelegram, setWorkTelegram] = useState(profile.workTelegram || '');
  const [workPhone, setWorkPhone] = useState(profile.workPhone || '');

  const createdAt = new Date(profile.createdAt ? profile.createdAt : '');
  const year = createdAt.getFullYear();
  const month = createdAt.getMonth() + 1;
  const date = createdAt.getDate();

  const saveContactsHandler = () => {
    if (workTelegram) {
      const tgError = validateTelegram(workTelegram);
      if (tgError) return notify.error(tgError);
    }

    if (workPhone) {
      const phoneError = validatePhone(workPhone);
      if (phoneError) return notify.error(phoneError);
    }

    if (!workTelegram && !workPhone) {
      return notify.error('Введите телефон или телеграм');
    }
    changeContacts({ workPhone, workTelegram });
  };

  useEffect(() => {
    if (!isSuccess) return;
    return notify.success('Сохранено');
  }, [isSuccess]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  return (
    <div>
      <div className="text-xl font-medium my-3">Общая информация</div>
      <div className="flex pt-2 justify-between ">
        <p>ID:</p>
        <p className="font-medium">{profile.id}</p>
      </div>
      <div className="flex justify-between">
        <p>Логин:</p>
        <p className="font-medium">{profile.login}</p>
      </div>
      <div className="flex justify-between">
        <p>Зарегистрирован:</p>
        <p className="font-medium">{`${year}-${
          month < 10 ? `0${month}` : month
        }-${date}`}</p>
      </div>
      <div className="flex justify-between">
        <p>Заработано всего:</p>
        <p className="font-medium">{profile.balance?.totalEarned} К</p>
      </div>
      <div className="text-xl font-medium mb-3 mt-9">Контактные данные</div>

      <div className=" w-full lg:max-w-sm">
        <label className="label">
          <span className="label-text">Телефон</span>
        </label>
        <input
          value={workPhone}
          onChange={(e) => setWorkPhone(e.target.value)}
          className="input pr-8 input-bordered w-full input-sm "
        />
        <label className="label">
          <span className="label-text">Телеграм</span>
        </label>
        <input
          value={workTelegram}
          onChange={(e) => setWorkTelegram(e.target.value)}
          className="input pr-8 input-bordered w-full input-sm "
        />
      </div>
      <div>
        <button
          className="btn btn-sm btn-success mt-5"
          onClick={saveContactsHandler}
        >
          Сохранить
        </button>
      </div>
    </div>
  );
};
