import { useState } from "react";
import { MoneyEarningStat, Tabs } from "@/components";
import { useAppSelector } from "@/hooks/useRedux";
import { useGetStatsFromPeriodsQuery } from "@/redux/api/statsAPI";
import { app } from "@/redux/features/appSlice";
import { IWebmasterStatsFromPeriods } from "@/types";

import "./Collapse.css";

export const Collapse = () => {
  const { activeStatsTab } = useAppSelector(app);
  const { data, isLoading } = useGetStatsFromPeriodsQuery(null);

  const [collapseIsOpen, setCollapseOpen] = useState(true);

  const tabs: [keyof IWebmasterStatsFromPeriods, number][] = data
    ? (Object.keys(data) as Array<keyof typeof data>).map((item) => [
        item,
        data[item].total,
      ])
    : [];

  return (
    <>
      <div
        className={`collapse collapse-arrow ${
          collapseIsOpen ? "collapse-open" : "collapse-close"
        } theme-border bg-base-100  rounded-md mb-5 shadow`}
      >
        <div
          className="collapse-title cursor-pointer lg:text-xl px-5 lg:px-10 font-medium"
          onClick={() => setCollapseOpen(!collapseIsOpen)}
        >
          Статистика по заработку
        </div>
        <div className="collapse-content px-5 lg:px-10">
          {isLoading
            ? ""
            : data && (
                <>
                  <Tabs tabs={tabs} />
                  <MoneyEarningStat
                    data={data[activeStatsTab]}
                    tabKey={activeStatsTab}
                  />
                </>
              )}
        </div>
      </div>
    </>
  );
};
