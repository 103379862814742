import { LeadStatus } from '@/types';

export const getStatusText = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.LEAD:
      return 'В обработке';
    case LeadStatus.IN_WORK:
      return 'В работе';
    case LeadStatus.APPROVED:
      return 'Подтвержден';
    case LeadStatus.REJECTED:
      return 'Отклонен';
  }
};
