import { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useLoginMutation } from '@/redux/api/webmasterAPI';
import { PasswordInput, RulesAndConvention } from '@/components';
import { routing, tokenLSKey } from '@/constants';
import { IErrorResponse } from '@/types';

const leadsPage = `/${routing.CABINET}/${routing.LEADS}`;

export const LoginForm = () => {
  const [loginWebmaster, { error, data, isLoading }] = useLoginMutation();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isPublicOfferChecked, setPublicOfferChecked] = useState(false);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (!login || !password) return notify.error('Введите логин и пароль');
    if (!isPublicOfferChecked) {
      return notify.error('Примите условия Пользовательского соглашения');
    }

    loginWebmaster({ login, password });
  };

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(tokenLSKey, data.accessToken);
    window.location.href = leadsPage;
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    localStorage.removeItem(tokenLSKey);
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem(tokenLSKey)) window.location.href = leadsPage;
  }, []);

  return (
    <form className="login-form mt-4" onSubmit={submitHandler}>
      <input
        type="text"
        placeholder="Логин"
        className="input input-bordered input-primary w-full mb-5"
        value={login}
        onChange={(e) => setLogin(e.target.value)}
      />
      <div className="relative">
        <PasswordInput
          password={password}
          changeHandler={setPassword}
          className="input-primary"
          placeholder="пароль"
        />
      </div>
      <button
        className={`btn ${isLoading ? 'loading' : ''} btn-primary w-full`}
      >
        Войти
      </button>
      <RulesAndConvention
        publicOfferState={isPublicOfferChecked}
        changePublicOfferState={setPublicOfferChecked}
      />
    </form>
  );
};
