import { capitalizeFirstLetter } from '@/utils';
import { useEffect, useState } from 'react';

export const useTitle = (title: string) => {
  const [documentTitle, setTitle] = useState(capitalizeFirstLetter(title));
  useEffect(() => {
    document.title = `${capitalizeFirstLetter(documentTitle)} | ley-lid.ru`;
  }, [documentTitle]);

  return [setTitle];
};
