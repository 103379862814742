import { ReferrerStatusType } from '@/types';

export const getReferrerStatusText = (status: ReferrerStatusType) => {
  switch (status) {
    case ReferrerStatusType.BEGINNER:
      return 'Джуниор';
    case ReferrerStatusType.ADVANCED:
      return 'Мидл';
    case ReferrerStatusType.MASTER:
      return 'Сеньор';
  }
};
