import { tokenLSKey } from "@/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { removeApiKey, setApiKey } from "../features/webmasterSlice";

export const apiTokensAPI = createApi({
  reducerPath: "apiTokensAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/api-tokens/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getToken: builder.query<{ token: string | null }, void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.token) dispatch(setApiKey(data.token));
        } catch (error) {}
      },
    }),
    createToken: builder.mutation<{ token: string }, void>({
      query: () => ({
        url: "",
        method: "POST",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setApiKey(data.token));
        } catch (error) {}
      },
    }),
    removeToken: builder.mutation<any, void>({
      query: () => ({
        url: "",
        method: "DELETE",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(removeApiKey());
        } catch (error) {}
      },
    }),
    regenerateToken: builder.mutation<any, void>({
      query: () => ({
        url: "",
        method: "PATCH",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setApiKey(data.token));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetTokenQuery,
  useCreateTokenMutation,
  useRemoveTokenMutation,
  useRegenerateTokenMutation,
} = apiTokensAPI;
