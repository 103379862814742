import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppSelector } from "@/hooks/useRedux";
import { webmaster } from "@/redux/features/webmasterSlice";
import { useSetBankCardsMutation } from "@/redux/api/webmasterAPI";
import { TrashIcon } from "@/components";
import { bankNames } from "@/constants";
import { validateBankCards } from "@/utils";
import { IBankCard } from "@/types";

export const BankCardsBlock = () => {
  const { bankCards } = useAppSelector(webmaster);
  const [cards, setCards] = useState<IBankCard[]>(bankCards);

  const [saveCards, { error, isSuccess }] = useSetBankCardsMutation();

  const addCardInput = () => {
    if (cards.length >= 7) return;
    setCards((prev) => [...prev, { bank: "none", number: "", fio: "" }]);
  };

  const removeCardInput = (id: number) => {
    setCards((prev) => {
      const arr = [...prev];
      arr.splice(id, 1);
      return [...arr];
    });
  };

  const cardChangeHandler = (
    idx: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    let cardsData = [...cards];
    cardsData[idx] = { ...cardsData[idx], [name]: value };
    setCards([...cardsData]);
  };

  const saveCardHandler = () => {
    const error = validateBankCards(cards);
    if (error) return notify.error(error);
    saveCards({ bankCards: cards });
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify.success("Данные сохранены!");
  }, [isSuccess]);

  useEffect(() => {
    if (!error) return;
    notify.error("Что-то пошло не так");
  }, [error]);

  return (
    <div className="xl:w-2/3">
      <div className="text-xl font-medium my-3">Вывод средств</div>

      {cards.length ? (
        cards.map((input, i) => (
          <div className="flex lg:items-center gap-2 items-baseline" key={i}>
            <div className="form-control my-3 w-full">
              <div className="lg:input-group">
                <select
                  name="bank"
                  className="select select-bordered mb-3 lg:mb-0 select-sm w-full lg:w-auto"
                  value={input.bank}
                  onChange={(e) => cardChangeHandler(i, e)}
                >
                  <option value="none">Выберите банк</option>
                  {bankNames.map((b) => (
                    <option key={b} value={b}>
                      {b}
                    </option>
                  ))}
                  <option value="custom">Другой банк</option>
                </select>
                {input.bank === "custom" && (
                  <input
                    type="text"
                    placeholder="Название банка"
                    className="input input-bordered mb-3 lg:mb-0 input-sm w-full lg:w-1/3"
                    name="customBank"
                    value={input.customBank}
                    onChange={(e) => cardChangeHandler(i, e)}
                  />
                )}
                <input
                  type="text"
                  placeholder="Номер карты"
                  className="input input-bordered mb-3 lg:mb-0 input-sm w-full lg:w-1/3"
                  name="number"
                  value={input.number}
                  onChange={(e) => cardChangeHandler(i, e)}
                />
                <input
                  type="text"
                  placeholder="ФИО получателя"
                  className="input input-bordered mb-3 lg:mb-0 input-sm w-full lg:w-2/3"
                  name="fio"
                  value={input.fio}
                  onChange={(e) => cardChangeHandler(i, e)}
                />
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => removeCardInput(i)}>
              <TrashIcon />
            </div>
          </div>
        ))
      ) : (
        <div className="text-xl p-3 no-data-text font-thin">
          Пока что добавленных карт нет
        </div>
      )}

      <div className="flex mt-5 gap-3">
        <button onClick={saveCardHandler} className="btn btn-success btn-sm">
          сохранить
        </button>
        <button className="btn btn-primary btn-sm" onClick={addCardInput}>
          Добавить карту
        </button>
      </div>
    </div>
  );
};
